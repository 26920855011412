import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import styled, { useTheme } from "styled-components"
import Anchor from "@components/elements/anchor"
import theme from "../styles/theme"
import media from "../styles/media"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import { YoutubeEmbed } from "@components/common/youtube-embed"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const ImageWrapper = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.default};
  }

  ${props =>
    props.reverse &&
    media.md`
      order: 1;
    `}
`

ImageWrapper.propTypes = {
  reverse: PropTypes.bool,
}

const flexGap = { gap: "12rem", gapMd: "8rem", gapSm: "6rem" }

const CapterraPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const { shadow } = useTheme()

  return (
    <Layout
      fullWidth
      title={`Capterra`}
      description={`Klipfolio is the top rated cloud app for building dashboards and reports for your team or clients.`}
      marginless
    >
      <Flex
        {...flexGap}
        padding="10rem 0 8rem"
        margin="0 0 8rem"
        background="#013950"
      >
        <Container text>
          <Div center>
            <Flex gap="3rem" alignItems="center">
              <Heading color="white" fontSize="4rem" center>
                Your trusted BI reporting and dashboarding solution
              </Heading>
              <Paragraph
                color="white"
                fontSize="1.4rem"
                center
                lineHeight="140%"
                fontWeight="600"
              >
                Klipfolio is the top rated cloud app for building dashboards and
                reports for your team or clients. Klipfolio helps you stay in
                control of your business by giving you visibility into your most
                important data and metrics.
              </Paragraph>
              <Button.KlipsSimple
                background="white"
                color="#013950"
                text="#013950"
                noticeColor="white"
                lineHeight="1"
                width="max-content"
                padding="1rem 2.75rem"
              >
                Get Klips Free
              </Button.KlipsSimple>
            </Flex>
          </Div>
        </Container>
      </Flex>
      <Flex {...flexGap} margin="0 0 8rem">
        <Container text>
          <Flex gap="2rem" center>
            <Heading as="h2" margin="0 0 2rem">
              Trusted by over 50,000 game changers and their teams
            </Heading>
            <Div>
              <Image file={images["5bfee070-fa2a-443d-acc4-99925b51ba2c"]} />
            </Div>
          </Flex>
        </Container>
        <Container>
          <Flex {...flexGap}>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <Flex gap="2rem">
                <Heading as="h2">
                  Bring all your company data into one spot
                </Heading>
                <Paragraph>
                  Bring order to a chaotic data universe using Klipfolio
                  dashboards. Draw data from your web services, database, Excel
                  and Google Sheets to create unified company dashboards.
                </Paragraph>
                <Paragraph>
                  There&apos;s always a way to get your data into Klipfolio. You
                  can even email it to our system using an attachment.
                </Paragraph>
                <div>
                  <Image
                    file={images["8ad820e6-ff59-46e8-bfad-206253c448e8"]}
                  />
                </div>
                <Anchor link="https://www.klipfolio.com/integrations" arrow>
                  Learn more about our integrations
                </Anchor>
              </Flex>
              <Image
                file={images["46b90309-cffb-464a-b2e5-7804e34a9a7d"]}
                radius={0.5}
                borderStyle={{
                  boxShadow: shadow.default,
                }}
              />
            </Grid>
            <Grid
              columns="3fr 2fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <ImageWrapper reverse>
                <Image
                  file={images["848983cc-1469-47c9-bc2a-d30e63bec2c1"]}
                  radius={0.5}
                  borderStyle={{
                    boxShadow: shadow.default,
                  }}
                />
              </ImageWrapper>
              <Flex gap="2rem">
                <Heading as="h2">
                  Deploy reports and dashboards in hours
                </Heading>
                <Paragraph>
                  You can start building your BI reports and dashboards today
                  with Klipfolio. Use the free trial to explore all our features
                  and connect to your data wherever it is.
                </Paragraph>
                <Paragraph>
                  Have a TV and a casting device in your office? You could have
                  a TV dashboard set up before you head home for dinner tonight.
                </Paragraph>
                <Anchor link="https://www.klipfolio.com/gallery" arrow>
                  Check out our gallery of pre-built content
                </Anchor>
              </Flex>
            </Grid>
            <Grid
              columns="2fr 3fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="flex-start"
            >
              <Flex gap="2rem">
                <Heading as="h2">
                  Count on world-class support to help get you started
                </Heading>
                <Paragraph>
                  No matter what you&apos;re trying to do, we&apos;ve got your
                  back. If you&apos;ve read any reviews, you&apos;ll hear our
                  support team is second to none. Our free online support will
                  help you at every step along the way.
                </Paragraph>
                <Anchor
                  link="https://www.klipfolio.com/dashboard-services"
                  arrow
                >
                  Need help with an urgent project? Check out our dedicated
                  support options!
                </Anchor>
              </Flex>
              <ImageWrapper>
                <Image
                  file={images["712c9be4-1c5e-4d66-b3c1-ba9c8d58410f"]}
                  radius={0.5}
                  borderStyle={{
                    boxShadow: shadow.default,
                  }}
                />
              </ImageWrapper>
            </Grid>
            <Div padding="4rem" background="#edf3fd" borderRadius="1rem">
              <Heading center as="h2" margin="0 0 2rem">
                Klipfolio is ranked among the world&apos;s best
              </Heading>
              <Grid gap="2rem" columns="repeat(3, 1fr)">
                <Flex
                  gap="2rem"
                  padding="2rem"
                  background="white"
                  borderRadius="1rem"
                >
                  <StaticImage
                    objectFit="scale-down"
                    src="https://assets.capterra.com/badge/e942df76569a32314b5e9ffc11177359.png?v=2091505&p=130237"
                  />
                  <Heading as="h5">Great visualization service</Heading>
                  <Paragraph fontSize="1rem">
                    We often advise this service to our partners, besides that,
                    we use Klipfolio ourselves as well. The biggest advantage is
                    the ability to combine a lot of different sources and
                    possibility to build your own custom dashboards with all the
                    data you want to see at one place.
                  </Paragraph>
                </Flex>
                <Flex
                  gap="2rem"
                  padding="2rem"
                  background="white"
                  borderRadius="1rem"
                >
                  <StaticImage
                    objectFit="scale-down"
                    src="https://assets.capterra.com/badge/e942df76569a32314b5e9ffc11177359.png?v=2091505&p=130237"
                  />
                  <Heading as="h5">
                    The best dashboard design and visualization experience
                  </Heading>
                  <Paragraph fontSize="1rem">
                    All the complexities are hidden under simple and elegant UI.
                    You won&apos;t have to worry complex technical tweaks to
                    pull data from different sources. Their documentation and
                    support are superb!
                  </Paragraph>
                </Flex>
                <Flex
                  gap="2rem"
                  padding="2rem"
                  background="white"
                  borderRadius="1rem"
                >
                  <StaticImage
                    objectFit="scale-down"
                    src="https://assets.capterra.com/badge/e942df76569a32314b5e9ffc11177359.png?v=2091505&p=130237"
                  />
                  <Heading as="h5">
                    I saved more than 300 hours and increased customers by x3
                  </Heading>
                  <Paragraph fontSize="1rem">
                    I&apos;m saving costs and increasing customers having great
                    results in real time. High quality software at low cost. The
                    potential is awesome.
                  </Paragraph>
                </Flex>
              </Grid>
            </Div>
            <Grid
              columns="3fr 2fr"
              columnsMd="1fr"
              gap="4rem"
              alignItems="center"
            >
              <YoutubeEmbed style={{ borderRadius: "1rem" }}>
                <iframe
                  src="https://www.youtube.com/embed/egbfoY_LfnY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </YoutubeEmbed>
              <Flex gap="2rem">
                <Heading as="h2">
                  Case Study: Versature&apos;s best-in-class dashboards
                </Heading>
                <Paragraph>
                  See how Versature used Klipfolio to get their data out into
                  the organization where it has maximum impact.
                </Paragraph>
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Flex>
      <KlipsEOPCta />
    </Layout>
  )
}

CapterraPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default CapterraPage

export const pageQuery = graphql`
  query CapterraQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "5bfee070-fa2a-443d-acc4-99925b51ba2c"
            "f23bd911-54bc-4798-b3d8-8c2e0c462d71"
            "8ad820e6-ff59-46e8-bfad-206253c448e8"
            "46b90309-cffb-464a-b2e5-7804e34a9a7d"
            "848983cc-1469-47c9-bc2a-d30e63bec2c1"
            "712c9be4-1c5e-4d66-b3c1-ba9c8d58410f"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
